import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { ProtectedPage, ValidateOperations } from './auth/components/ProtectedPage';
import { Suspense, lazy } from 'react';
import { PageLayout } from './layout/PageLayout';
import { selectAllowedOperations } from './app/allowedOperationSlice';
import { selectFeatureInfo } from './app/featureSlice';
import { SiteIdValidationPage } from './auth/components/SiteIdValidationPage';
import { useAppSelector } from './app/hooks';

export const App = () => {
    const featureInfo = useAppSelector(selectFeatureInfo);
    const enabledFeatures = featureInfo.features ?? null;
    const isLoadingEnabledFeatures = featureInfo.isLoadingEnabledFeatures;

    const allowedOperationStore = useAppSelector(selectAllowedOperations);
    const isLoadingAllowedOperations = allowedOperationStore.isOperationsLoading;

    const Home = lazy(() => import('./pages/Home'));
    const Profile = lazy(() => import('./pages/Profile'));
    const Edges = lazy(() => import('./pages/Edges'));
    const EdgeRequestList = lazy(() => import('./components/EdgeRequest/EdgeRequestList'));
    const EdgeRequestCreateForm = lazy(() => import('./components/EdgeRequest/EdgeRequestCreate/EdgeRequestCreateForm'));
    const EdgeRequestPreview = lazy(() => import('./components/EdgeRequest/EdgeRequestPreview/EdgeRequestPreview'));
    const DeploymentList = lazy(() => import('./components/Deployment/DeploymentList'));
    const DeploymentDetailsWrapper = lazy(() => import('./components/Deployment/DeploymentDetails/DeploymentDetailsWrapper'));
    const DeploymentConfiguration = lazy(() => import('./components/Deployment/DeploymentConfiguration/DeploymentConfiguration'));
    const DeploymentWorkload = lazy(() => import('./components/Deployment/DeploymentWorkloads/DeploymentWorkload'));
    const ModuleDefinitionList = lazy(() => import('./components/ModuleDefinition/ModuleDefinitionList'));
    const ModuleDefinitionListByModuleId = lazy(() => import('./components/ModuleDefinition/ModuleDefinitionListByModuleId'));
    const ModuleDefinitionJson = lazy(() => import('./components/ModuleDefinition/ModuleDefinitionJson'));
    const ModuleDefinitionForm = lazy(() => import('./components/ModuleDefinition/ModuleDefinitionCreate/ModuleDefinitionForm'));
    const WorkloadDefinitionList = lazy(() => import('./components/WorkloadDefinition/WorkloadDefinitionList'));
    const WorkloadDefinitionJson = lazy(() => import('./components/WorkloadDefinition/WorkloadDefinitionJson'));
    const WorkloadDefinitionForm = lazy(() => import('./components/WorkloadDefinition/WorkloadDefinitionCreate/WorkloadDefinitionForm'));
    const SiteVariablesDetails = lazy(() => import('./components/Deployment/DeploymentDetails/SiteVariablesDetails'));
    const Administration = lazy(() => import('./components/Administration/Administration'));
    const RoleDefinitionJson = lazy(() => import('./components/Administration/RoleDefinition/RoleDefinitionJson'));
    const RoleDefinitionCreate = lazy(() => import('./components/Administration/RoleDefinition/RoleDefinitionCreate/RoleDefinitionCreate'));
    const SiteOverview = lazy(() => import('./components/Site/SiteOverview'));
    const SiteDetails = lazy(() => import('./components/Site/SiteDetails'));

    return (
        <PageLayout isLoadingEnabledFeatures={isLoadingEnabledFeatures} isLoadingAllowedOperations={isLoadingAllowedOperations}>
            {enabledFeatures &&
                <Suspense fallback={<></>}>
                    <Routes>
                        <Route index path="/" element={<Home />} />

                        <Route path="edgerequests" element={
                            <ProtectedPage resourceOperations={["Vanderlande.EdgeMan/edgeRequest/read"]} />
                        }>
                            <Route index element={<EdgeRequestList />} />
                            <Route path="new" element={
                                <ProtectedPage
                                    resourceOperations={[
                                        "Vanderlande.EdgeMan/edgeRequest/register/write",
                                        "Vanderlande.EdgeMan/configuration/read",
                                        "Vanderlande.EdgeMan/edgeRequest/validateDeviceId/action",
                                    ]}
                                    children={<Suspense fallback=""><EdgeRequestCreateForm /></Suspense>}
                                    validate={ValidateOperations.All}
                                />
                            } />
                            <Route path=":id" element={<Suspense fallback=""><EdgeRequestPreview /></Suspense>} />
                            <Route path="preview/:id" element={<Redirect path={`/edgerequests/`} />} />
                        </Route>

                        <Route path="edgerequestreview/:id" element={<Redirect path={`/edgerequests/`} />} />

                        {/* Ensure that any invalid route navigates back to the dashboard */}
                        <Route path="*" element={<Navigate to="/" />} />

                        {/* Admin Routes */}
                        <Route element={
                            <ProtectedPage resourceOperations={[
                                "Vanderlande.EdgeMan/roleAssignment/read",
                                "Vanderlande.EdgeMan/admin/cache/delete",
                                "Vanderlande.EdgeMan/roleDefinition/read",
                                "Vanderlande.EdgeMan/notification/read",
                                "Vanderlande.EdgeMan/auditevent/read"
                            ]} />
                        }>
                            <Route path="administration">
                                <Route index element={<Administration />} />
                            </Route>
                        </Route>
                        <Route path="administration/roledefinition/:roleName" element={
                            <ProtectedPage resourceOperations={["Vanderlande.EdgeMan/roleDefinition/read"]} children={<RoleDefinitionJson />} />
                        } />
                        <Route path="administration/roledefinition/new" element={
                            <ProtectedPage
                                resourceOperations={[
                                    "Vanderlande.EdgeMan/roleDefinition/write",
                                    "Vanderlande.EdgeMan/roleDefinition/validate/action",
                                    "Vanderlande.EdgeMan/roleDefinition/read"
                                ]}
                                children={<RoleDefinitionCreate />}
                                validate={ValidateOperations.All}
                            />
                        } />

                        <Route path="edges/*" element={<Edges />} />

                        <Route element={
                            <ProtectedPage resourceOperations={["Vanderlande.EdgeMan/roleAssignment/loggedInUser/read"]} />
                        }>
                            <Route path="profile" element={<Profile />} />
                        </Route>

                        <Route path="site" element={
                            <ProtectedPage resourceOperations={["Vanderlande.EdgeMan/hub/edgeList/read"]} />
                        }>
                            <Route index element={<SiteOverview />} />
                            <Route path=":siteId"
                                element={
                                    <SiteIdValidationPage children={<SiteDetails />} />
                                }
                            />
                        </Route>
                        {/* Deployment Routes */}
                        <Route path="deployments" element={
                            <ProtectedPage resourceOperations={["Vanderlande.EdgeMan/deployment/read"]} />
                        }>
                            <Route index element={<DeploymentList />} />
                        </Route>
                        <Route path="deploymentdetails/:deploymentId" element={
                            <ProtectedPage resourceOperations={["Vanderlande.EdgeMan/deployment/read"]} />
                        } >
                            <Route index element={<Suspense fallback=""><DeploymentDetailsWrapper /></Suspense>} />
                            <Route path="workloads" element={
                                // page without id is for adding workload to deployment, 
                                // so user has to have both read and write access for deployment workload
                                // and permission to retrieve all workload definitions
                                <ProtectedPage
                                    resourceOperations={[
                                        "Vanderlande.EdgeMan/workloadDefinition/read",
                                        "Vanderlande.EdgeMan/deployment/workload/read",
                                        "Vanderlande.EdgeMan/deployment/workload/write"
                                    ]} children={<DeploymentWorkload />}
                                    validate={ValidateOperations.All}
                                />
                            } />
                            <Route path="workloads/:workloadId" element={
                                // page with id is for view/edit workload, 
                                // so user can access it when he has read access, and in case of no write permissions button for update won't be visible
                                // and labels will be rendered instead of inputs in grid
                                <ProtectedPage
                                    resourceOperations={[
                                        "Vanderlande.EdgeMan/deployment/workload/read"
                                    ]} children={<DeploymentWorkload />} />
                            } />
                        </Route>
                        <Route path="deploymentsitevariables/:deploymentId" element={
                            <ProtectedPage
                                resourceOperations={["Vanderlande.EdgeMan/deployment/read"]}
                                children={<SiteVariablesDetails />} />
                        } />
                        <Route path="deploymentconfiguration/:deploymentId" element={
                            <ProtectedPage
                                resourceOperations={["Vanderlande.EdgeMan/deployment/configuration/read"]}
                                children={<DeploymentConfiguration />} />
                        } />

                        {/* Workload Definition Route // Write and schema access not allowed without read permission */}
                        <Route path="workloaddefinitions" element={
                            <ProtectedPage resourceOperations={["Vanderlande.EdgeMan/workloadDefinition/read"]} />
                        }>
                            <Route index element={<WorkloadDefinitionList />} />
                            <Route path="new" element={
                                <ProtectedPage
                                    resourceOperations={[
                                        "Vanderlande.EdgeMan/workloadDefinition/write",
                                        "Vanderlande.EdgeMan/workloadDefinition/validate/action"
                                    ]}
                                    children={<WorkloadDefinitionForm />}
                                    validate={ValidateOperations.All}
                                />
                            } />
                            <Route path=":workloadDefinitionId" element={<WorkloadDefinitionJson />} />
                        </Route>

                        {/* Module Definition Route // Write and schema access not allowed without read permission */}
                        <Route path="moduledefinitions" element={
                            <ProtectedPage resourceOperations={["Vanderlande.EdgeMan/moduleDefinition/read"]} />
                        }>
                            <Route index element={<ModuleDefinitionList />} />
                            <Route path="new" element={
                                <ProtectedPage
                                    resourceOperations={[
                                        "Vanderlande.EdgeMan/moduleDefinition/write",
                                        "Vanderlande.EdgeMan/moduleDefinition/validate/action"
                                    ]}
                                    children={<ModuleDefinitionForm />}
                                    validate={ValidateOperations.All}
                                />
                            } />
                            <Route path=":moduleDefinitionId" element={<ModuleDefinitionListByModuleId />} />
                            <Route path=":moduleDefinitionId/:version" element={<ModuleDefinitionJson />} />
                        </Route>
                    </Routes>
                </Suspense>
            }
        </PageLayout >
    )
};

const Redirect = (props: { path: string }) => {
    const { id } = useParams();
    const { path } = props;
    const newPath = `${path}${id}`;
    return <Navigate to={newPath} />;
}